import { useParams } from 'react-router-dom';
import { useAppSelector } from '@/hooks';
import { Text, Button, Spinner } from '@radix-ui/themes';
import '@/features/layout/header/appHeader.scss';
import { useEffect, useRef, useState } from 'react';
import { TimerIcon } from '@radix-ui/react-icons';
import { toast } from 'react-toastify';
import { psdEngineApi } from '@/services/psdEngine';
import { ReactComponent as DownloadIcon } from '@/assets/icons/download-minimalistic-bold.svg';
import { VariationExportOptions } from './VariationExportOptions';
import useDownloader from 'react-use-downloader';
import useCaptureEvent from '@/hooks/useCaptureEvent';

export const GenerateVariationsButton = () => {
  const { collectionId } = useParams();
  const { download, percentage } = useDownloader();
  const captureEvent = useCaptureEvent();

  const { mockupVariations, activeSmartObject, mockup } = useAppSelector(
    (state) => state.collectionReducer
  );
  const { myProjects } = useAppSelector((state) => state.workspaceReducer);

  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isPreparing, setIsPreparing] = useState<boolean>(false);
  const [exportData, setExportData] = useState({});

  const { data: downloadAllStatus, isError } =
    psdEngineApi.useGetDownloadAllStatusQuery(
      { id: localStorage.getItem('task_id') || '' },
      {
        skip:
          !localStorage.getItem('task_id') ||
          !localStorage.getItem('task_collection_id') ||
          localStorage.getItem('task_collection_id') !==
            myProjects
              .find((project) => project.uuid === collectionId)
              ?.id.toString(),
        pollingInterval: 1000,
        refetchOnMountOrArgChange: true,
      }
    );

  const [generatingProgress, setGeneratingProgress] = useState(0);

  useEffect(() => {
    if (isError) {
      localStorage.removeItem('task_id');
      localStorage.removeItem('task_collection_id');

      setIsGenerating(false);
      setGeneratingProgress(0);

      toast.error(
        `There was an error while generating variants. If this error persists, please contact the support.`,
        {
          toastId: 'download_all_status_failed',
          position: 'bottom-right',
          autoClose: 2800,
        }
      );
      captureEvent('Mockup Download Failed', {
        export_options: {
          error: 'GetDownloadAllStatus failed',
          ...exportData,
          download_type: 'all variants',
          position: 'useEffect if (isError) ',
        },
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isGenerating) {
      toast.info(`Generating variants started.`, {
        toastId: 'generating_info',
        position: 'bottom-right',
        autoClose: 2800,
      });
    }
  }, [isGenerating]);

  useEffect(() => {
    if (downloadAllStatus?.data.state === 'PENDING') {
      setIsGenerating(true);

      setGeneratingProgress(
        Math.round(
          (downloadAllStatus.data.completed_tasks /
            downloadAllStatus.data.total_tasks) *
            100
        )
      );
    }
    if (downloadAllStatus?.data.state === 'COMPLETED') {
      localStorage.removeItem('task_collection_id');
      setIsGenerating(false);
      setIsDownloading(true);
      setGeneratingProgress(0);

      const downloadVariationsZip = async () => {
        try {
          toast.info(`Downloading variants started.`, {
            toastId: 'download_zip_info',
            position: 'bottom-right',
            autoClose: 2800,
          });
          await fetch(
            `${API_BASE_URL}mockups/variations/${localStorage.getItem(
              'task_id'
            )}/download-all-complete`,
            {
              headers: new Headers({
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json',
              }),
              method: 'POST',
              body: JSON.stringify({
                smart_object_id: (exportData as any).smart_object_id,
              }),
            }
          )
            .then((res) => res.blob())
            .then(async (res) => {
              await download(
                URL.createObjectURL(res),
                `${mockup.name} (${mockupVariations.length} variants) - Dynamic Mockups.zip`
              );

              toast.success(`Variants downloaded successfully.`, {
                toastId: 'download_zip_success_info',
                position: 'bottom-right',
                autoClose: 2800,
              });
              captureEvent('Mockup Download Succeeded', {
                export_options: {
                  ...exportData,
                  download_type: 'all variants',
                },
              });
            })
            .catch((error) => {
              console.log(error);
              if (error.message.includes('QuotaExceededError')) {
                toast.error(
                  `You do not have enough disk space to store this file.`,
                  {
                    toastId: 'diskspace_status_failed',
                    position: 'bottom-right',
                    autoClose: 2800,
                  }
                );
              }
              captureEvent('Mockup Download Failed', {
                export_options: {
                  error: 'DownloadVariationsZip failed',
                  ...exportData,
                  download_type: 'all variants',
                  position: 'downloadVariationsZip',
                  errorData: JSON.stringify(error),
                },
              });
            })
            .finally(() => {
              setIsDownloading(false);
              localStorage.removeItem('task_id');
            });
        } catch (error) {
          toast.error(
            `There was an error while generating variants. If this error persists, please contact the support.`,
            {
              toastId: 'download_all_status_failed',
              position: 'bottom-right',
              autoClose: 2800,
            }
          );
          captureEvent('Mockup Download Failed', {
            export_options: {
              error: 'DownloadVariationsZip failed',
              ...exportData,
              download_type: 'all variants',
              position: 'downloadVariationsZip',
              errorData: JSON.stringify(error),
            },
          });

          setIsDownloading(false);
          localStorage.removeItem('task_id');
        }
      };

      downloadVariationsZip();
    }
    if (downloadAllStatus?.data.state === 'FAILED' || isError) {
      localStorage.removeItem('task_id');
      localStorage.removeItem('task_collection_id');
      setIsGenerating(false);
      setGeneratingProgress(0);

      toast.error(
        `There was an error while generating variants. If this error persists, please contact the support.`,
        {
          toastId: 'download_all_status_failed',
          position: 'bottom-right',
          autoClose: 2800,
        }
      );
      captureEvent('Mockup Download Failed', {
        export_options: {
          error: 'DownloadAllStatus failed',
          ...exportData,
          download_type: 'all variants',
          position: 'downloadAllStatus?.data.state === "FAILED" || isError',
        },
      });
    }
  }, [downloadAllStatus]);

  const variationExportOptionsDialog = useRef();

  return (
    <>
      {activeSmartObject && (
        <>
          <VariationExportOptions
            ref={variationExportOptionsDialog}
            setIsGenerating={setIsGenerating}
            setIsDownloading={setIsDownloading}
            setIsPreparing={setIsPreparing}
            setExportData={setExportData}
          />
          <Button
            color="blue"
            className="download-all-button"
            onClick={() => {
              captureEvent('Export Mockups Modal Opened');
              (variationExportOptionsDialog?.current as any)?.openDialog();
            }}
            disabled={
              !mockupVariations.length ||
              isGenerating ||
              isPreparing ||
              isDownloading
            }
          >
            {!isPreparing && !isGenerating && !isDownloading && (
              <>
                <DownloadIcon className="icon white" />
                <Text size="1" weight="medium">
                  Export Mockups
                </Text>
              </>
            )}
            {isPreparing && (
              <>
                <Spinner />
                <Text size="1" weight="medium">
                  Preparing variants...
                </Text>
              </>
            )}
            {isGenerating && (
              <>
                <Spinner />
                <Text size="1" weight="medium">
                  {`Generating variants - ${generatingProgress}%`}
                </Text>
              </>
            )}
            {isDownloading && (
              <>
                <Spinner />
                <Text size="1" weight="medium">
                  {percentage && percentage > 0
                    ? `Downloading - ${percentage}%`
                    : `Downloading...`}
                </Text>
              </>
            )}
          </Button>
        </>
      )}
    </>
  );
};
